import { ApiClient } from "./ApiClient"
import { IdResult } from "./Common"
import { getResource, postResource, putResource } from "./Search"
import { NotificationTypeId, SupportedLanguage, TenantId } from "./WellKnowIds"

export type DetailedUserModel =
    {
        id: string
        firstName?: string
        lastName?: string
        username: string
        enabled: boolean
        groupNames: string[]
    }

export type AddUserModel =
    {
        firstName: string
        lastName: string
        userMailNickname: string
        groupNames: string[]
    }

export type UsernameAndPassword =
    {
        username: string
        firstTimePassword: string
    }

export type PermissionGroup =
    {
        permissionGroupId: number
        entraIdObjectName: string
        shortNameGr: string
        descriptionGr?: string
        isUserSelectionAllowed: boolean
    }

export type IdentityUser =
    {
        id: string
        displayName?: string
        username: string
    }

export type UserInformation =
    {
        userId: number
        name: string
        username: string
        firstName?: string
        lastName?: string
        permissions: string[]
        groups: string[]
        preferredTenantId?: TenantId
        preferredLanguage: SupportedLanguage
        isImpersonation?: boolean
    }

export type UserListingViewModel =
    {
        userId: number
        name: string
        username?: string
        groups?: string[]
    }

export enum NotificationEffectiveStatusFilter { All = 0, UnreadOnly = 1, ReadOnly = 2, ArchivedOnly = 3 }

export type NotificationViewModel =
    {
        notificationId: number
        notificationTypeId: NotificationTypeId
        ticketId?: number
        applicationId?: number
        customerId?: number
        sampleId?: number
        documentId?: number
        inspectionId?: number
        message: string
        isRead: boolean
        dateCreated: Date
        groupInformation?: { isDeleted: boolean, allNotifications: NotificationGroupInformationViewModel[] }
    }

export type NotificationGroupInformationViewModel =
    {
        targetUserId: number
        targetUser: string
        isPrimaryUser: boolean
        isRead: boolean
        isDeleted: boolean
    }

export class UsersApi {
    constructor(private readonly api: ApiClient) { }

    public getCurrentSilent = async (timeout?: number) => {
        try {
            const r = await this.api.execute<UserInformation>(200, 'GET', `api/users/current`, undefined, undefined, timeout || 2000)
            return r.kind === 'success' ? r.data : null
        }
        catch {
            return null
        }
    }

    public addUser = (model: AddUserModel) =>
        postResource<AddUserModel, UsernameAndPassword>(this.api, `api/users/accounts`, model)

    public getPermissionGroups = () =>
        getResource<PermissionGroup[]>(this.api, `api/users/accounts/groups`, [])

    public getIdentityUsers = () =>
        getResource<IdentityUser[]>(this.api, `api/users/accounts`, [])

    public getIdentityUser = (id: string) =>
        getResource<DetailedUserModel | undefined>(this.api, `api/users/accounts/${id}`, undefined)

    public updateIdentityUser = (model: DetailedUserModel) =>
        putResource<IdentityUser, IdResult>(this.api, `api/users/accounts/${model.id}`, model)

    public deactivateIdentityUser = (id: string) =>
        putResource<null>(this.api, `api/users/accounts/${id}/account-status/deactivate`, null)

    public reactivateIdentityUser = (id: string) =>
        putResource<null>(this.api, `api/users/accounts/${id}/account-status/reactivate`, null)

    public resetIdentityUserPassword = (id: string) =>
        postResource<null, UsernameAndPassword>(this.api, `api/users/accounts/${id}/reset-password`, null)

    public getCurrent = () =>
        this.api.onUnexpected(
            this.api.execute<UserInformation>(200, 'GET', `api/users/current`)
            , "Could not load user information. Please contact system administrator immediately.", null)

    public impersonateUser = (targetUserId: number | undefined, targetGroups: string[] | undefined) =>
        putResource(this.api, `api/users/current/impersonate-groups`, { targetGroups, targetUserId }, { expected: 204 })

    public getUsers = () =>
        this.api.onUnexpected(
            this.api.execute<UserListingViewModel[]>(200, 'GET', `api/users`)
            , "Could not load users information. Please contact system administrator immediately.", [] as UserListingViewModel[])

    public getNotifications = (filter: NotificationEffectiveStatusFilter) =>
        this.api.onUnexpected(
            this.api.execute<{ numberOfUnread: number, notifications: NotificationViewModel[] }>(200, 'GET', `api/users/current/notifications`, { filter })
            , "Could not load notifications", { numberOfUnread: 0, notifications: [] as NotificationViewModel[] })

    public markNotificationAsRead = (...notificationIds: number[]) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'PUT', `api/users/current/notifications/mark-as-read`, undefined, notificationIds)
            , "Could not mark as read", null)

    public markNotificationAsUnread = (...notificationIds: number[]) =>
        putResource(this.api, `api/users/current/notifications/mark-as-unread`, notificationIds, { expected: 204 })

    public deleteNotifications = (...notificationIds: number[]) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/users/current/notifications`, undefined, notificationIds)
            , "Could not delete notification", null)
}