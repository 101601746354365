import DateOnly from "../utils/dateOnly"
import { ActivityCategoryView, BasicActivityGroupView } from "./ActivityCategory"
import { AddressLikeBindingModel, AddressLikeFullModel, CustomerAddress, EMPTY_ADDRESS } from "./Address"
import { ApiClient, BlobResponse } from "./ApiClient"
import { ActivityUnitFragmentViewModel, BasicActivityGroupDetailed } from "./Application"
import { CertificationState, CertificationStateView } from "./CertificationStandards"
import { CustomerConsultantAssignmentViewModel } from "./Consultant"
import { ContactInformation } from "./ContactInformation"
import { AffirmationBindingModel, AffirmationDocumentType } from "./Document"
import { CustomerViewModel } from "./Inspection"
import { LegalRepresentativeBase } from "./LegalRepresentative"
import { NaturalPersonBasicProperties } from "./Person"
import { PerCertificationStandardView, PricingAnalysisView } from "./Pricing"
import { ProgrammeViewModel } from "./Programme"
import { RegionUnit } from "./Region"
import { ApiDescription, EMPTY_API_DESCRIPTION, FilterWithValue, Paginated, PaginationRequest, SortCriterionWithValue, combineQueryParams, fakePaginated, getResource, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, postResource } from "./Search"
import { EMPTY_SUBCONTRACTOR, SubcontractorRelationshipListingViewModel, SubcontractorViewModel } from "./Subcontractors"
import { ActivityUnitSpecificKindId, ActivityUnitStatusId, ExportExcelTimeout, LegalTypeId, VirtualAgreementStatusId } from "./WellKnowIds"

export type CustomerListingViewModel =
    {
        customerId: number
        taxRegistrationNumber: string
        distinctiveTitle: string
        firstName?: string
        lastName?: string
        regionUnit: string
        basicActivityGroups: string[]
        certificationStandards: string[]
        significance?: number
    }

export type CustomerBindingModel =
    Partial<NaturalPersonBasicProperties>
    & {
        customerId: number
        legacyIds: string[]

        legalTypeId?: LegalTypeId
        taxRegistrationNumber: string

        dateOfBirth?: Date
        distinctiveTitle: string
        distinctiveTitleLatin: string

        previousOrganizationId?: number
        previousOrganizationDate?: Date

        taxOfficeId?: number

        address: Partial<CustomerAddress>
        mailingAddress?: Partial<CustomerAddress>
        contactInformation: Partial<ContactInformation>
        operationalLicense?: string
        businessRegistration?: string
        businessTitle?: string
        businessTitleLatin?: string
        legalRepresentative?: Partial<LegalRepresentativeBase>
        note?: string
        significance?: number
        gdprConsent?: boolean

        firstNameLatin?: string
        lastNameLatin?: string
        middleNameLatin?: string
    }

export const EMPTY_CUSTOMER: CustomerBindingModel =
{
    customerId: 0,
    distinctiveTitle: '',
    distinctiveTitleLatin: '',
    taxRegistrationNumber: '',
    contactInformation: {},
    legacyIds: [],
    address: EMPTY_ADDRESS,
}

export type CustomerUpdated = { customerId: number }

export type CustomerDetails = { customerId: number } & CustomerBindingModel

export type ActivityGroup = ActivityCategoryView

export type PropertyBindingModel =
    AddressLikeBindingModel &
    {
        customerPropertyId?: number
    }

export type PropertyListingViewModel = PropertyBindingModel & AddressLikeFullModel & { customerPropertyId: number }

export type PropertyViewModel = PropertyBindingModel & { customerPropertyId: number }

export const EMPTY_PROPERTY: PropertyBindingModel =
{
    addressLine1: '',
    communityId: 0,
    postalCode: '',
}

export type PropertyAdded = { customerPropertyId: number }

export const EMPTY_CUSTOMER_ACTIVITY_UNIT_VIEW: ActivityUnitViewModel =
{
    customerActivityUnitId: 0,
    persistentActivityUnitId: null as unknown as number,
    activityUnitStatusId: 0 as any,
    isCorrectable: false,
    communityId: 0,
    fragments: [],
    unitCertificationStandards: [],
    snapshotStartDate: null as unknown as Date,
    programmes: []
}

export type ActivityUnitViewModel =
    {
        customerActivityUnitId: number
        persistentActivityUnitId: number
        activityUnitStatusId: ActivityUnitStatusId
        isCorrectable: boolean
        snapshotStartDate: Date
        snapshotEndDate?: Date
        communityId: number
        addressLine1?: string
        postalCode?: string
        toponym?: string
        units?: number
        squareMeters?: number
        areaStremmata?: number
        activityUnitSpecificKindId?: ActivityUnitSpecificKindId
        weightKg?: number
        cbm?: number
        unitIdentifier?: string
        note?: string
        ownerFirstName?: string
        ownerLastName?: string
        unitCertificationStandards: CertificationState[]
        fragments: ActivityUnitFragmentViewModel[]
        programmes: ProgrammesInfoViewModel[]
        isPropagatingMaterial?: boolean
        isSubcontractorOnlyLocation?: boolean
    }

export type ProgrammesInfoViewModel =
    {
        programmeInfoNameId: string
        programmeId: number
    }

export type ActivityUnitListingViewModel =
    {
        customerActivityUnitId: number
        persistentActivityUnitId: number
        activityUnitInitialDate: Date
        snapshotStartDate: Date
        snapshotEndDate?: Date
        regionUnitName: string
        municipalityName: string
        communityName: string
        toponym?: string
        unitCertificationStandards: CertificationStateView[]
        unitIdentifier?: string
        note?: string
        owned: boolean
        fragments: string[]
        activityUnitStatusId: ActivityUnitStatusId
        isPropagatingMaterial?: boolean
        isSubcontractorOnlyLocation?: boolean
        isCorrection: false
    }

export type ActivityUnitAdded = { customerActivityUnitId: number }

export type StoredItemBindingModel =
    {
        activityCategoryId: number
    }

type CustomerWarehouseModelBase =
    {
        communityId: number
        toponym: string
        unitIdentifier: string
        squareMeters?: number
        storageType?: string
        ownerFirstName?: string
        ownerLastName?: string
        storedItems: StoredItemBindingModel[]
    }

export type CustomerWarehouseViewModel =
    CustomerWarehouseModelBase & {
        customerWarehouseId: number
        regionId: number
        regionUnitId: number
        municipalityId: number
        municipalityUnitId: number
    }

export type CustomerWarehouseBindingModel = CustomerWarehouseModelBase & { customerWarehouseId?: number }

export const EMPTY_CUSTOMER_WAREHOUSE: CustomerWarehouseViewModel =
{
    customerWarehouseId: 0,
    communityId: 0,
    municipalityId: 0,
    municipalityUnitId: 0,
    regionId: 0,
    regionUnitId: 0,
    toponym: '',
    unitIdentifier: '',
    storedItems: [],
}

export type CustomerWarehouseListingView =
    {
        customerWarehouseId: number
        regionUnitName: string
        municipalityName: string
        municipalityUnitName: string
        communityName: string
        toponym: string
        unitIdentifier: string
        owned: boolean
    }

export type AncestorIds = {
    activityUnitId: number
    persistentActivityUnitId: number
}

export type ActivityUnitRecordListingViewModelBase =
    {
        persistentActivityUnitId: number
        activityUnitInitialDate: Date
        snapshotStartDate: Date
        snapshotEndDate?: Date
        unitCertificationStandards: CertificationStateView[]
        regionUnitName: string
        municipalityName: string
        communityName: string
        toponym?: string
        unitIdentifier?: string
        fragments: string[]
        products: string[]
        ancestorIds: AncestorIds[]
        units?: number
        squareMeters?: number
        areaStremmata?: number
        activityUnitSpecificKindId?: ActivityUnitSpecificKindId
        weightKg?: number
        cbm?: number
        note?: string
        activityUnitStatusId: ActivityUnitStatusId
    }

export type ActivityUnitRecordListingViewModel =
    {
        customerActivityUnitId: number
    } & ActivityUnitRecordListingViewModelBase

export type CustomerEffectiveCostViewModel =
    {
        billingPeriodId?: number
        customerAgreementId: number
        certificationStandard: string
        needsAgreement: boolean
        startDate: Date
        endDate: Date
        effectiveTotalCostNoVat: number
        effectiveTotalCostNoVatCrop?: number
        effectiveTotalCostNoVatLivestock?: number
        effectiveTotalCostNoVatProcessing?: number
        effectiveTotalCostNoVatBeekeeping?: number
        effectiveTotalCostNoVatAquaculture?: number
        subperiods: SubperiodViewModel[]
    }

export type SubperiodViewModel =
    {
        startDate: Date
        endDate: Date
        days: number
        periodCostNoVat: number
        totalCostNoVat: number
        totalCostNoVatCrop?: number
        totalCostNoVatLivestock?: number
        totalCostNoVatProcessing?: number
        totalCostNoVatBeekeeping?: number
        totalCostNoVatAquaculture?: number
    }

export type CustomerBillingListingViewModel =
    {
        customerBillingId: number
        pricingComponent: string
        totalCostNoVat: number
        startDate?: Date
        endDate?: Date
        description?: string
        active: boolean
        needsAgreement?: boolean
        modifiedUser: string
        dateModified: Date
        createdUser: string
        dateCreated: Date
        hasPricingAnalysis: boolean
        initialCustomerBillingId?: number
    }

export type CustomerBillingViewModel =
    {
        customerBillingId: number
        pricingComponentId: number
        totalCostNoVat: number
        startDate?: Date
        endDate?: Date
        description?: string
        pricingAnalysis?: PricingAnalysisView
        active: boolean
    }

export type CustomerBillingBindingModel =
    Omit<CustomerBillingViewModel, 'customerBillingId'> & { isCloneAction?: boolean }

export const EMPTY_CUSTOMER_BILLING_VIEWMODEL: CustomerBillingViewModel =
{
    customerBillingId: 0,
    pricingComponentId: 0,
    totalCostNoVat: 0,
    startDate: new Date(),
    active: false
}

export enum OmniSearchResultType { Customer = 1, Inspector = 2 }

export type CustomerOmniSearchResult =
    {
        searchResultType: OmniSearchResultType.Customer,

        customerId: number
        taxRegistrationNumber: string
        legalTypeId: LegalTypeId
        distinctiveTitle: string
        regionUnit: string
        municipality: string
        businessTitle?: string
        firstName?: string
        lastName?: string
        middleName?: string
        significance?: number

        contactInformation?: Partial<ContactInformation>

        inspectionDate?: Date
        inspectorFullName?: string
        evaluatorFullName?: string
        inspectionStatus?: string

        activeApplications: number[]

        certificationStandards?: { name: string, isActive: boolean }[]
        basicActivityGroups?: string[]

        isVerifiedUser?: boolean
    }

export type InspectorOmniSearchResult =
    {
        searchResultType: OmniSearchResultType.Inspector,

        inspectorId: number
        firstName: string
        lastName: string
        contactInformation: ContactInformation
    }

export type OmniSearchResult = InspectorOmniSearchResult | CustomerOmniSearchResult

export type OmniSearchResponse =
    {
        searchResults: OmniSearchResult[]
        hasMore: boolean
    }

export const EMPTY_OMNISEARCH_RESPONSE: OmniSearchResponse =
{
    searchResults: [],
    hasMore: false,
}

export type CustomerOtherActivitiesListing =
    {
        certificationStandardName: string,
        organizationCertificationName: string,
        expirationDate: Date,
        customerOtherActivityId: number
    }

export type CustomerOtherActivityInformationListing =
    {
        customerOtherActivityInformationId: number,
        certificationStandardName: string,
        organizationCertificationName: string,
        firstName: string,
        lastName: string,
        contactInformation: Partial<ContactInformation>
    }

export type CustomerDistinctLocations =
    {
        hasActiveApplication: boolean
        regionUnits: RegionUnit[]
    }

export type CustomerInspectionSummary =
    {
        date: Date
        inspectionType: string
        isPartial: string
        isUnannounced: string
        isRisk: string
        certificationStandards: string[]
        inspectorName?: string
        inspectionStatus: string
        evaluationDate?: Date
        evaluationUser?: string
        inspectionId: number
        ticketId?: number
        ticketStatus?: string
    }

export type Diagnostic = { lineNumber?: number, message: string }

export type BatchAddCustomersDiscountDataLine =
    {
        lineNumber: number
        taxRegistrationNumber: string
        discount: number
        discountLevel: string
        year: number
        startDate: Date
        actualEndDate: Date
        endDate?: Date
        durationInMonths?: number
        notes?: string
        existingDiscount?: string
    }

export type BatchAddCustomersDiscountResponse =
    {
        data: BatchAddCustomersDiscountDataLine[]
        errors: Diagnostic[]
        warnings: Diagnostic[]
    }

export type DataLine =
    {
        lineNumber: number
        taxRegistrationNumber?: string
        customerId?: number
        dateOfApplication: Date
        consultantInfo?:
        {
            consultantId: number
            consultancyName: string
            certificationStandard: string
            certificationStandardId: number
        }
        programmeAssignmentInfo:
        {
            programmeId: number
            name: string
        }[],
    }

export type BatchUpsertCustomerDataResponse =
    {
        data: DataLine[]
        errors: Diagnostic[]
        warnings: Diagnostic[]
    }

export type DownloadAllDocsQuery =
    {
        includeCustomerAgreements: boolean
        includeLastCertificate: boolean
        includeCustomerView: boolean
    }



/*
Νομίζω πρέπει να ερχονται μόνο συμβασεις που δεν ειναι επεκτάσεις
*/
export type BillingTimelineView =
    {
        hasAnyBillingRelatedErrors: boolean
        agreements: {
            customerAgreementId: number
            agreementStatusId: number
            certificationStandardId: number
            from: DateOnly
            to?: DateOnly
            virtualAgreements: VirtualAgreementTimelineView[]
        }[]
    }

export type VirtualAgreementTimelineView = {
    id: number
    code: string
    typeId: number
    statusId: VirtualAgreementStatusId
    startDate: DateOnly
    endDate?: DateOnly
    virtualBillingPeriods: VirtualPeriodView[]
}

export type VirtualPeriodView = {
    id: number
    startDate: DateOnly
    endDate: DateOnly
    effectiveTotalCostNoVat: number
    calculatedEffectiveDiscount?: {
        asPercentage?: number
        asFlatAmount?: number
    }
    subPeriods: VirtualSubperiodView[]
}

export type VirtualSubperiodView = {
    startDate: DateOnly
    endDate: DateOnly
    proportionalTotalNoVat: number
    annualTotalNoVat: number
    perCertificationStandard: PerCertificationStandardView
    calculatedEffectiveDiscount?: {
        asPercentage?: number
        asFlatAmount?: number
    }
    isAdjustmentForAnnualCalculation: boolean
    customerBillingId: number
}

export type CustomerHealthCheckViewModel =
    {
        numberOfAccountingErrorDiagnostics: number
        numberOfRoguePastInspections: number
        numberOfActiveApplications: number
        numberOfPlannedInspectionsThisYear: number
        hasActiveCustomerAgreement: boolean
        overallHealthCheck: OverallHealthCheck
    }

export enum OverallHealthCheck {

    Healthy = 0,
    Warning = 1,
    Critical = 2,
}


export class CustomersApi {
    constructor(private readonly api: ApiClient) { }

    public getDetails = (id: number, onNotFoundSupressNotifications?: boolean) =>
        this.api.onUnexpected(
            this.api.execute<CustomerDetails>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${id}`),
            "Could not load customer details",
            EMPTY_CUSTOMER, undefined, onNotFoundSupressNotifications)

    public changeLegalType = (id: number) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/customers/${id}/change-legal-type`, null, { expected: 204 })

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/customers?${queryParams.toString()}`)
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<CustomerListingViewModel>>(200, 'GET', href),
            "Could not load customers list",
            fakePaginated([]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/customers`),
            "Could not load api description",
            EMPTY_API_DESCRIPTION)

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }

    public getCustomersExportForLegacy = () =>
        this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/reports/feed-legacy-export`, undefined, undefined, undefined, undefined, undefined, true),
            "Could not export given search to Excel",
            null)

    public getAssignedConsultants = (customerId: number) =>
        getResource<CustomerConsultantAssignmentViewModel[]>(this.api, `api/tenants/${this.api.tenantId}/customers/${customerId}/consultants`, [])

    public getActivityGroups = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<BasicActivityGroupView[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups`),
            "Could not load activity groups", [] as BasicActivityGroupView[])

    public getActivityGroupsDetailed = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<BasicActivityGroupDetailed[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/detailed`),
            "Could not load activity groups (detailed)", [] as BasicActivityGroupDetailed[])

    public getSubcontractors = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<SubcontractorRelationshipListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/subcontractors`),
            "Could not load subcontractors",
            [] as SubcontractorRelationshipListingViewModel[])

    public getSubcontractor = (customerId: number, subcontractorId: number) =>
        this.api.onUnexpected(
            this.api.execute<SubcontractorViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/subcontractors/${subcontractorId}`),
            "Could not load subcontractor",
            EMPTY_SUBCONTRACTOR)

    public getProperties = (customerId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<PropertyListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/properties`),
            "Could not load properties",
            [] as PropertyListingViewModel[])

    public getProperty = (customerId: number, activityCategoryId: number, propertyId: number) =>
        this.api.onUnexpected(
            this.api.execute<PropertyViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/properties/${propertyId}`),
            "Could not load property",
            EMPTY_PROPERTY)

    public getActivityUnits = (customerId: number, activityCategoryId: number, pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getActivityUnitsFromHref(`api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/activity-units?${queryParams.toString()}`)
    }

    public getActivityUnitsFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<ActivityUnitListingViewModel>>(200, 'GET', href),
            "Could not load activity units list",
            fakePaginated([]))

    public getActivityUnitsExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[], customerId: number, basicActivityGroupId: number) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${basicActivityGroupId}/activity-units/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }

    public describeActivityUnits = (customerId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/activity-units`)
            , "Could not load api description", EMPTY_API_DESCRIPTION)

    public getActivityUnit = (customerId: number, activityCategoryId: number, activityUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<ActivityUnitViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/activity-units/${activityUnitId}`),
            "Could not load activity unit",
            EMPTY_CUSTOMER_ACTIVITY_UNIT_VIEW)

    public batchDeleteActivityUnits = (customerId: number, basicActivityGroupId: number, customerActivityUnitIds: number[] | null, filters: FilterWithValue[] | null) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'DELETE', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${basicActivityGroupId}/activity-units/batch`, mapFiltersToQueryParamsObject(filters ?? []), { customerActivityUnitIds: customerActivityUnitIds })
            , "Could delete activity units", null)

    public getWarehouses = (customerId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerWarehouseListingView[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/warehouses`),
            "Could not load warehouses",
            [] as CustomerWarehouseListingView[])

    public getWarehouse = (customerId: number, activityCategoryId: number, warehouseId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerWarehouseViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/warehouses/${warehouseId}`),
            "Could not load warehouse",
            EMPTY_CUSTOMER_WAREHOUSE)

    public getActivityUnitRecords = (customerId: number, activityCategoryId: number, activityUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<ActivityUnitRecordListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/activity-units/${activityUnitId}/records`),
            "Could not load records",
            [] as ActivityUnitRecordListingViewModel[])

    public getActivityUnitRecordIds = (customerId: number, activityCategoryId: number, activityUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<number[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/activity-groups/${activityCategoryId}/activity-units/${activityUnitId}/record-ids`),
            "Could not load activity unit record ids",
            [])

    public convert = (customerId: number, inspectionId?: number, complianceDecisionId?: number, ticketId?: number, dateOfApplication?: Date) =>
        this.api.onUnexpected(
            this.api.execute<{ applicationId: number }>(200, 'POST', `api/tenants/${this.api.tenantId}/customers/${customerId}/convert`, undefined, { inspectionId: inspectionId, complianceDecisionId: complianceDecisionId, ticketId: ticketId, dateOfApplication }),
            "Could not create an application for customer",
            null)

    public getHints = async (customerId?: number, taxRegistrationNumber?: string) => {
        const result = await this.api.execute<{ customerId?: number, taxRegistrationNumber?: string, activeApplications: { applicationId: number, username: string }[], deactivationRequested: string[] }>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/hints`, { customerId: customerId, taxRegistrationNumber: taxRegistrationNumber })
        if (result.kind === "unexpected") return { activeApplications: [], deactivationRequested: [] }
        else return result.data
    }

    public getProgrammes = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<ProgrammeViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/programmes`),
            "Could not get programmes", [] as ProgrammeViewModel[])



    public getCustomerBillingTimeline = (customerId: number) =>
        getResource<BillingTimelineView | null>(this.api, `api/tenants/${this.api.tenantId}/customers/${customerId}/billing-timeline`, null)

    public getBillingList = (customerId: number, includeInactive: boolean, returnAnnualOnly: boolean) =>
        this.api.onUnexpected(
            this.api.execute<CustomerBillingListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/billing`, { includeInactive: includeInactive, returnAnnualOnly }),
            "Could not get billing list", [] as CustomerBillingListingViewModel[])

    public getEffectiveCosts = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerEffectiveCostViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/effective-costs`),
            "Could not get effective costs", [] as CustomerEffectiveCostViewModel[])

    public getBillingDetails = (customerId: number, customerBillingId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerBillingViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/billing/${customerBillingId}`),
            "Could not get billing details", EMPTY_CUSTOMER_BILLING_VIEWMODEL as CustomerBillingViewModel)

    public upsertBillingDetails = (customerId: number, id: number | undefined, model: CustomerBillingBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/customers/${customerId}/billing${id === undefined ? '' : '/' + id}`, undefined, model),
            "Could not upsert billing details", null)

    public recalculateBillingPeriods = (customerId: number) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/customers/${customerId}/billing/periods`, {})

    public addBillingBasedOnDate = (customerId: number, date: Date) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/customers/${customerId}/billing/new`, undefined, { startDate: date }),
            "Could not add billing", null)

    public deleteBilling = (customerId: number, customerBillingId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/customers/${customerId}/billing/${customerBillingId}`)
            , "Could not delete the billing", null)

    public batchConvertToSettled = (billingIds: number[] | null) =>
        this.api.onUnexpected(
            this.api.execute<{ recordsChanged: number }>(200, 'POST', `api/tenants/${this.api.tenantId}/customers/billing`, undefined, { billingIds: billingIds }),
            "Could not update billings", null)

    public omniSearch = (term: string | undefined, customerId: number | undefined) =>
        this.api.onUnexpected(
            this.api.execute<OmniSearchResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/omnisearch`, { term: term, customerId: customerId }),
            "Could not search customers", null, false, true)

    public lookupByTrn = (taxRegistrationNumber: string) =>
        getResource<null | { customerId: number }>(this.api, `api/tenants/${this.api.tenantId}/customers/lookup`, null, { queryParams: { taxRegistrationNumber } })

    public getOtherActivities = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerOtherActivitiesListing[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/other-activities`),
            "Δεν φορτώθηκαν οι άλλες δραστηριότητες", [] as CustomerOtherActivitiesListing[])

    public getOtherActivityInformation = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerOtherActivityInformationListing[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/other-activities/information`),
            "Δεν φορτώθηκαν οι άλλες δραστηριότητες", [] as CustomerOtherActivityInformationListing[])

    public getDistinctLocations = (customerId: number, certificationStandard: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerDistinctLocations>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/distinct-locations`, { certificationStandard: certificationStandard }),
            "Δεν φορτώθηκαν οι τοποθεσίες του πελάτη", null)

    public getInspectionsSummary = (customerId: number, pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getInspectionSummaryFromHref(`api/tenants/${this.api.tenantId}/customers/${customerId}/inspections?${queryParams.toString()}`)
    }

    public getInspectionSummaryFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<CustomerInspectionSummary>>(200, 'GET', href),
            "Δεν ειναι δυνατή η εμφάνιση της σύνοψης πελάτη",
            fakePaginated([]))

    public getCustomerView = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/customer-view`),
            "Δεν είναι δυνατη η εμφάνισης της εικόνας του πελάτη", null)

    public getCustomerViewExcel = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/customer-view/export`, undefined, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Δεν είναι δυνατή η εμφάνιση της εικόνας πελάτη σε excel",
            null)

    public checkTrns = (trns: string[]) =>
        this.api.onUnexpected(
            this.api.execute<{ found: string[], missing: string[] }>(200, 'POST', `api/tenants/${this.api.tenantId}/customers/check`, undefined, { taxRegistrationNumbers: trns }),
            "Check failed",
            { found: [], missing: [] })

    public correct = (customerId: number, taxRegistrationNumber: string) =>
        this.api.onUnexpected(
            this.api.execute<null>(204, 'PUT', `api/tenants/${this.api.tenantId}/customers/${customerId}/correction`, undefined, { taxRegistrationNumber }), "Correction failed", undefined)

    public getCustomerAnnualReviewReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/customers/reports/review-per-year`, null, { blob: true, queryParams: { year } })

    public batchUpsertData = async (model: { file: File, isDryRun: boolean, sheetName: string }): Promise<BatchUpsertCustomerDataResponse | {} | null> => {
        const formData = new FormData()
        formData.append('file', model.file)
        formData.append('isDryRun', model.isDryRun.toString())
        formData.append('sheetName', model.sheetName)

        const response = await this.api.execute<BatchUpsertCustomerDataResponse>(204, 'PUT', `api/tenants/${this.api.tenantId}/customers/batch/upsert-data`, undefined, formData, this.api.maxTimeoutMs)
        switch (response.kind) {
            case 'success': return {}
            case 'unexpected':
                if (response.status === 200 || response.status === 400)
                    return response.data as BatchUpsertCustomerDataResponse
                else
                    return await this.api.onUnexpected(Promise.resolve(response), "Could not batch import data", null)
        }
    }

    public batchUpsertDiscountsData = async (model: { file: File, isDryRun: boolean, sheetName: string }): Promise<BatchAddCustomersDiscountResponse | {} | null> => {
        const formData = new FormData()
        formData.append('file', model.file)
        formData.append('isDryRun', model.isDryRun.toString())
        formData.append('sheetName', model.sheetName)

        const response = await this.api.execute<BatchAddCustomersDiscountResponse>(204, 'PUT', `api/tenants/${this.api.tenantId}/customers/batch/upsert-discounts-data`, undefined, formData, this.api.maxTimeoutMs)
        switch (response.kind) {
            case 'success': return {}
            case 'unexpected':
                if (response.status === 200 || response.status === 400)
                    return response.data as BatchAddCustomersDiscountResponse
                else
                    return await this.api.onUnexpected(Promise.resolve(response), "Could not batch import data", null)
        }
    }

    public batchGenerateAffirmations = async (type: AffirmationDocumentType, model: AffirmationBindingModel, customerIds?: number[] | null, filters?: FilterWithValue[] | null) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/customers/generation/${type}-affirmation`, { customerIds, ...model }, {
            queryParams: mapFiltersToQueryParamsObject(filters ?? []),
            timeout: this.api.maxTimeoutMs
        })

    public bulkDownloadAllDocs = async (downloadAll: DownloadAllDocsQuery, customerIds: number[] | null, filters: FilterWithValue[]) =>
        postResource<{}, BlobResponse>(this.api, `api/tenants/${this.api.tenantId}/customers/bulk/documents/download-all`, { customerIds, ...downloadAll }, {
            queryParams: mapFiltersToQueryParamsObject(filters),
            blob: true,
            timeout: this.api.maxTimeoutMs,
        })

    public getCustomersAndActivityCategoryReport = (activityCategoryId: number, customerIds: number[] | null, filters: FilterWithValue[]) =>
        postResource<unknown, BlobResponse>(this.api, `api/tenants/${this.api.tenantId}/customers/reports/customer-and-category`, { customerIds, activityCategoryId }, {
            queryParams: mapFiltersToQueryParamsObject(filters),
            blob: true,
            timeout: this.api.maxTimeoutMs,
        })

    public getCustomerHealthCheck = (customerId: number) =>
        getResource<null | CustomerHealthCheckViewModel>(this.api, `api/tenants/${this.api.tenantId}/customers/${customerId}/health-check`, null)
}