import DateOnly from '../utils/dateOnly'
import { idOrEmptyRoute, Prettify } from '../utils/utils'
import { ApiClient } from "./ApiClient"
import { IdDisplayPair, IdResult, StronglyTypedId } from './Common'
import { getResource, postResource, putResource } from "./Search"

export enum InputTypeId {
    None = 0,
    Table = 1,
    Tuple = 2,
    Vector = 3,
    PlainText = 4,
    Boolean = 5,
    Decimal = 6,
    Month = 7,
    Year = 8,
    RichText = 9,
    Entity = 10,
    Checklist = 11,
    CalendarDate = 12,
    Integer = 13,
    MultiEntity = 14,
}

export enum TemplateItemTypeId {
    Label = 0,
    Table = 1,
    Tuple = 2,
    Vector = 3,
    PlainText = 4,
    YesNo = 5,
    Compliance = 6,
    RichText = 7,
    Checklist = 8,
    CalendarDate = 9,
}

export type AnsweredDigitalDocument =
    {
        id: AnsweredDigitalDocumentId
        templateId: number
        header?: AnsweredSheet
        body: AnsweredSheet[]
        footer?: AnsweredSheet
    }

export type AnsweredSheet =
    {
        id: AnsweredSheetId
        location: LayoutLocation
        sheetNumber: number
        answers: AnsweredDigitalDocumentItem[]
    }

export type AnsweredDigitalDocumentId = StronglyTypedId<'AnsweredDigitalDocumentId'>
export type TemplateItemId = StronglyTypedId<'TemplateItemId'>
export type AnsweredSheetId = StronglyTypedId<'AnsweredSheetId'>
export type AnsweredDigitalDocumentItemId = StronglyTypedId<'AnsweredDigitalDocumentItemId'>
export type DigitalDocumentTemplateId = StronglyTypedId<'DigitalDocumentTemplateId'>

export type AnsweredDigitalDocumentItem<TAnswer extends Answer = Answer> =
    {
        id?: AnsweredDigitalDocumentItemId
        templateItemId: TemplateItemId
        answer?: TAnswer
        secondaryAnswer?: Answer
        elaborationText?: RichTextContent
        notApplicable?: boolean
    }

export type Answer =
    | undefined
    | NoneAnswer
    | PlainTextAnswer
    | RichTextAnswer
    | BooleanAnswer
    | DecimalAnswer
    | TableAnswer
    | EntityAnswer
    | YearAnswer
    | ChecklistAnswer
    | TupleAnswer
    | CalendarDateAnswer
    | IntegerAnswer
    | MultiEntityAnswer

type AnswerBase<T extends InputTypeId> = {
    inputTypeId: T
}

export type NoneAnswer = AnswerBase<InputTypeId.None>
export type TableAnswer = AnswerBase<InputTypeId.Table> & {
    value: Answer[][],
    numbering: string[] | undefined
    automaticSummation: Answer[] | undefined
}
export type ChecklistAnswer = AnswerBase<InputTypeId.Checklist> & { value: BooleanAnswer[] }
export type TupleAnswer = AnswerBase<InputTypeId.Tuple> & { value: Answer[] }
export type VectorAnswer = AnswerBase<InputTypeId.Vector> & { value: Answer[] }
export type PlainTextAnswer = AnswerBase<InputTypeId.PlainText> & { value: string }
export type RichTextAnswer = AnswerBase<InputTypeId.RichText> & { value: RichTextContent }
export type CalendarDateAnswer = AnswerBase<InputTypeId.CalendarDate> & { value: DateOnly }
export type BooleanAnswer = AnswerBase<InputTypeId.Boolean> & { value: boolean }
export type DecimalAnswer = AnswerBase<InputTypeId.Decimal> & { value: number }
export type EntityAnswer = AnswerBase<InputTypeId.Entity> & { value: number, displayValue?: string }
export type MonthAnswer = AnswerBase<InputTypeId.Month> & { value: number }
export type YearAnswer = AnswerBase<InputTypeId.Year> & { value: number }
export type IntegerAnswer = AnswerBase<InputTypeId.Integer> & { value: number }
export type MultiEntityAnswer = AnswerBase<InputTypeId.MultiEntity> & { value: { value: number, displayValue?: string }[] }

export type RichTextContent =
    {
        contentTypeId: RichTextContentTypeId
        content: RichTextEditorContent
    }

export type RichTextEditorContent = StronglyTypedId<'RichTextEditorContent', unknown>

export enum RichTextContentTypeId {
    Lexical = 1,
}

export type DigitalDocumentTemplateListing =
    {
        id: DigitalDocumentTemplateId
        metadata: DigitalDocumentTemplateMetadata
        previewDocumentId: AnsweredDigitalDocumentId
    }

export type DigitalDocumentTemplate =
    {
        id: DigitalDocumentTemplateId
        metadata: DigitalDocumentTemplateMetadata
        header: TemplateItem[]
        body: TemplateItem[]
        footer: TemplateItem[]
    }

export type TemplateItem =
    | Label
    | PlainTextQuestion
    | RichTextQuestion
    | YesNoQuestion
    | ComplianceQuestion
    | TableQuestion
    | ChecklistQuestion
    | CalendarDateQuestion

export type ItemContext =
    {
        text: string
        reference?: string
        guidance?: string
        automaticFunctions: {
            functions: AutomaticFunctions
        }
    }

export enum AutomaticFunctions {
    None = 0,
    Sum = 1 << 0,
}

export type TemplateItemModel<T extends TemplateItemTypeId, TInputTypeId extends InputTypeId = InputTypeId> =
    {
        id: TemplateItemId
        typeId: T
        inputTypeId: TInputTypeId
        order: number
        location: LayoutLocation
        sizeHint: LayoutSizeHint
        level: ItemLevel
        context: ItemContext
        allowsElaboration: boolean
        allowsNotApplicable: boolean
        isReadOnly: boolean
        secondaryInput?: InputItemModel
    }

export type InputItemModel =
    | LabelInput
    | PlainTextInput
    | RichTextInput
    | BooleanInput
    | DecimalInput
    | SingleEntityInput
    | MonthInput
    | YearInput
    | TableInput
    | TupleInput
    | VectorInput
    | ChecklistInput
    | CalendarDateInput
    | IntegerInput
    | MultiEntityInput

type InputItemModelBase<TInputTypeId extends InputTypeId> = { inputTypeId: TInputTypeId, context: ItemContext }
export type LabelInput = InputItemModelBase<InputTypeId.None>
export type PlainTextInput = InputItemModelBase<InputTypeId.PlainText>
export type IntegerInput = InputItemModelBase<InputTypeId.Integer>
export type RichTextInput = InputItemModelBase<InputTypeId.RichText>
export type CalendarDateInput = InputItemModelBase<InputTypeId.CalendarDate>
export type BooleanInput = InputItemModelBase<InputTypeId.Boolean>
export type DecimalInput = InputItemModelBase<InputTypeId.Decimal>
export type SingleEntityInput = InputItemModelBase<InputTypeId.Entity> & {
    entityCategoryId: EntityCategoryId
    persistDisplayValue: boolean
}
export type MultiEntityInput = InputItemModelBase<InputTypeId.MultiEntity> & {
    entityCategoryId: EntityCategoryId
    persistDisplayValue: boolean
}

export enum EntityCategoryId {
    ProductId = 1,
}

export enum TableNumbering {
    None = 0,
}

export type MonthInput = InputItemModelBase<InputTypeId.Month>
export type YearInput = InputItemModelBase<InputTypeId.Year>
export type TableInput = InputItemModelBase<InputTypeId.Table> & { properties: { columns: InputItemModel[], numbering: TableNumbering } }
export type TupleInput = InputItemModelBase<InputTypeId.Tuple> & { properties: { items: InputItemModel[], orientation: InputOrientation } }
export type VectorInput = InputItemModelBase<InputTypeId.Vector> & { properties: { definition: InputItemModel } }
export type ChecklistInput = Prettify<InputItemModelBase<InputTypeId.Checklist> & { properties: { items: InputItemModel[] } }>

export enum InputOrientation {
    Horizontal = 0,
    Vertical = 1,
}

export enum LayoutLocation {
    Header = 1,
    Body = 2,
    Footer = 3,
}

export enum LayoutSizeHint {
    Default = 0,
    Half = 1,
    Third = 2,
}

export enum ItemLevel {
    Section = 1,
    Subsection = 2,
    Inner = 3,
}

export type TableQuestion = TemplateItemModel<TemplateItemTypeId.Table, InputTypeId.Table> & {
    properties: {
        columns: InputItemModel[]
        numbering: TableNumbering
    }
}

export type TupleQuestion = TemplateItemModel<TemplateItemTypeId.Tuple, InputTypeId.Tuple> & {
    properties: {
        items: InputItemModel[]
    }
}

export type VectorQuestion = TemplateItemModel<TemplateItemTypeId.Vector, InputTypeId.Vector> & {
    properties: {
        definition: InputItemModel
    }
}

export type ChecklistQuestion = TemplateItemModel<TemplateItemTypeId.Checklist, InputTypeId.Checklist> &
{
    properties: {
        items: InputItemModel[]
    }
}

export type PlainTextQuestion = TemplateItemModel<TemplateItemTypeId.PlainText, InputTypeId.PlainText>
export type RichTextQuestion = TemplateItemModel<TemplateItemTypeId.RichText, InputTypeId.RichText>

export type CalendarDateQuestion = TemplateItemModel<TemplateItemTypeId.CalendarDate, InputTypeId.CalendarDate>

export type YesNoQuestion = TemplateItemModel<TemplateItemTypeId.YesNo, InputTypeId.Boolean>

export type ComplianceQuestion = TemplateItemModel<TemplateItemTypeId.Compliance, InputTypeId.Boolean> & {
    nonComplianceRequiresElaboration: boolean
    complianceRequiresElaboration: boolean
}

export type Label = TemplateItemModel<TemplateItemTypeId.Label, InputTypeId.None>

export type DigitalDocumentTemplateMetadata =
    {
        title: string
        templateDescription: string
        footerText?: string
        subtitleText?: string
        templateOfficialVersioning?: string
        supportsMultipleSheets: boolean
    }

export type InputChoiceContextModel =
    {
        templateItemId: TemplateItemId
        answerKind: AnswerKind
        surroundingAnswer: Answer | undefined
        inputPath: InputPathSegmentModel[]
    }

export type InputPathSegmentModel =
    {
        segmentType: InputPathSegmentType.OneDimension,
        index: number
    } | {
        segmentType: InputPathSegmentType.TwoDimensions,
        rowIndex: number
        columnIndex: number
    }

export enum InputPathSegmentType {
    OneDimension = 1,
    TwoDimensions = 2,
}

export enum AnswerKind {
    Primary = 1,
    Secondary = 2,
}

export type ChoiceCollectionModel = {
    choices: ChoiceModel[]
    entityCategoryId: EntityCategoryId
}

export type ChoiceModel =
    | IdDisplayPair

export type AuxiliaryDataModel =
    {
        numbering?: string[]
        automaticSummation?: Answer[]
    }

export type DigitalDocumentTemplateBuilderContext =
    {
        inspectionId: number
        customerId?: number
        applicationId?: number
        dateOfInspection?: DateOnly
    }

export class DigitalDocumentsApi {
    constructor(private readonly api: ApiClient) { }

    private static readonly digitalDocumentTemplateBuilderContextKey = 'X-Bio-Dd-Ctx-O'

    public getDigitalDocumentTemplate = (digitalDocumentTemplateId: number) =>
        getResource<DigitalDocumentTemplate | null>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/templates/${digitalDocumentTemplateId}`, null)

    public getDigitalDocumentTemplates = () =>
        getResource<DigitalDocumentTemplateListing[]>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/templates`, [])

    public upsertDigitalDocument = (id: AnsweredDigitalDocumentId, digitalDocument: AnsweredDigitalDocument, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        putResource<AnsweredDigitalDocument, IdResult<AnsweredDigitalDocumentId>>(this.api, `api/tenants/${this.api.tenantId}/digital-documents${idOrEmptyRoute(id)}`, digitalDocument,
            {
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getDigitalDocument = (id: AnsweredDigitalDocumentId, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        getResource<AnsweredDigitalDocument | null>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}`, null,
            {
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getInputChoices = (id: AnsweredDigitalDocumentId, inputChoiceContext: InputChoiceContextModel, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        postResource<InputChoiceContextModel, ChoiceCollectionModel>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/inputs/choices`,
            inputChoiceContext,
            {
                onSuccessSilent: true,
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getInputAuxiliary = (id: AnsweredDigitalDocumentId, inputChoiceContext: InputChoiceContextModel, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        postResource<InputChoiceContextModel, AuxiliaryDataModel>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/inputs/auxiliary-data`,
            inputChoiceContext,
            {
                onSuccessSilent: true,
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    private static maybeAddBuilderContextHeader(builderContext?: DigitalDocumentTemplateBuilderContext) {
        if (!builderContext) return undefined
        return { [DigitalDocumentsApi.digitalDocumentTemplateBuilderContextKey]: JSON.stringify(builderContext) }
    }
}